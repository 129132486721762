.cta-img-slide {
    position:relative;
}
.cta-img-slide--has-overlying-img {
    padding: calc(65rem/16) 0;
    @media screen and (max-width: 767px) {
        padding: calc(40rem/16) 0 0;
    }
}
.cta-img-slide__content {
    position:absolute;
    top:0;
    left:0;
    right:0;
    width:100%;
    height:100%;
    z-index: 2;
}
.cta-img-slide--bg-cover-content .cta-img-slide__content {
    @media screen and (max-width: 767px) {
        position: relative;
    }
}
.cta-img-slide--has-overlying-img .cta-img-slide__text {
    padding: calc(80rem/16) 0;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) 0;
    }
}
.cta-img-slide__text {
    display:flex;
    justify-content:center;
    align-items:flex-start;
    flex-direction:column;
    height:100%;
    @media screen and (max-width: 767px) and (orientation: portrait) {
        align-items:center;
        justify-content:flex-end;
        padding-bottom:calc(30rem/16);
    }
}
.cta-img-slide__list-wrapper {
    display:flex;
    justify-content:center;
    flex-direction:column;
    height:100%;
    color:#fff;
    @media screen and (max-width: 767px) and (orientation: portrait) {
        align-items:center;
        justify-content:flex-end;
        padding-bottom:calc(10rem/16);
    }
}
.cta-img-slide__list-item {
    display:inline-block;
    padding-right:calc(12rem/16);
    padding-top:calc(16rem/16);
    @media screen and (max-width: 1400px) and (min-width: 768px) {
        padding-right:calc(8rem/16);
        padding-top:calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-right:calc(6rem/16);
        padding-top:calc(6rem/16);
    }
}

.cta-img-slide__list {
    font-size:calc(18rem/16);
    line-height:1.45;
    font-family:var(--font-headline);
    @media screen and (max-width: 1400px) and (min-width: 768px) {
        font-size:calc(15rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size:calc(14rem/16);
    }

}
.cta-img-slide__title {
    font-size:calc(30rem/16);
    line-height:1;
    font-family:var(--font-headline);
    color:var(--color-lightgrey);
    @media screen and (max-width: 767px) and (orientation: portrait) {
        font-size:calc(24rem/16);
        text-align:center;
    }
}
.cta-img-slide__bg {
    position:relative;
    overflow:hidden;
}
.cta-img-slide--bg-cover-content .cta-img-slide__bg {
    @media screen and (max-width: 767px) {
        position:absolute;
        width:100%;
        height:100%;
    }
}
.cta-img-slide__bg:before {
    content:'';
    position:absolute;
    display:block;
    left:0;
    right:0;
    top:0;
    width:100%;
    height:100%;
    z-index: 1;
    opacity: 0.8;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #000000 100%);
    @media screen and (max-width: 767px) {
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    }
}

.cta-img-slide__bg:after {
    //background-image:url("/static/img/pattern/desktop/pattern.svg");
    background-repeat: no-repeat;
    background-size: contain;
    content:'';
    position:absolute;
    display:block;
    left:0;
    right:0;
    top:0;
    width:100%;
    height:100%;
    z-index: 1;
}
.cta-img-slide--darken-bg-left .cta-img-slide__bg:before {
    background: linear-gradient(194.36deg, rgba(0,0,0,0) 0%, #000000 100%);
}
.cta-img-slide--darken-bg-center .cta-img-slide__bg:before {
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
}
.cta-img-slide--no-shadow .cta-img-slide__bg:before {
    background: none;
}
.cta-img-slide__overlying-img-wrapper {
    height:100%;
    @media screen and (max-width: 767px) {
        max-height:calc(220rem/16);
        text-align:center;
        margin: 0 auto;
        display:flex;
        align-items: flex-end;
        justify-content: center;
    }
}
.cta-img-slide__overlying-img {
    max-height:100%;
    width:auto;
}
.cta-img-slide__text-content {
    color:var(--color-lightgrey);
    margin-top:calc(10rem/16);
    font-size: calc(15rem/16);
    line-height:calc(22/15);
    width: 100%;
}
.cta-img-slide--text-black .cta-img-slide__text-content, .cta-img-slide--text-black .cta-img-slide__title {
    color: #000;
}
.cta-img-slide--headline-primary .cta-img-slide__title {
    color: var(--color-primary);
}