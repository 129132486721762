.main-footer {
    background-color:var(--color-primary);
    color:#fff;
}
.main-footer__list {
    display:flex;
    justify-content:center;
    align-items:center;
}
.main-footer__list-item {
    display:inline-block;
    font-size:calc(15rem/16);
    font-family:var(--font-headline-light);
    padding:0 calc(10rem/16);
    margin: calc(15rem/16) 0;
    line-height:1;
}
.main-footer__list-item + .main-footer__list-item {
    border-left:1px solid var(--color-lightgrey);
}