.hero-small__title {
    font-size:calc(48rem/16);
    font-family:var(--font-headline);
    letter-spacing:0.34px;
    line-height:calc(64/48);
    color:var(--color-lightgrey);
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        font-size:calc(32rem/16);
        line-height: calc(36/40);
    }
}
.hero-small__subtitle {
    font-size: calc(20rem/16);
    letter-spacing: 0.38px;
    font-family:var(--font-headline-light);
    color:var(--color-lightgrey);
    text-transform:uppercase;
    line-height:calc(40/30);
    padding-top:calc(15rem/16);
    display:flex;
    align-items:flex-start;
    @media screen and (max-width: 767px) {
        font-size:calc(18rem/16);
        line-height: calc(27/20);
    }
}
.hero-small__subtitle-icon {
    color:var(--color-primary);
    line-height: 1;
    margin-bottom: calc(3rem/16);
    font-size: calc(26rem/16);
    margin-right:calc(6rem/16);
}
.hero-small__body {
    padding-bottom: calc(100rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(30rem/16);
    }
}