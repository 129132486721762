.cookie-modal__title {
    font-size:calc(32rem/16);
    line-height: calc(45/32);
    text-align:center;
    color:var(--color-primary);
    font-family:var(--font-headline);
    margin: 0;
}
.cookie-modal .modal-header {
    text-align:center;
    align-items: center;
    justify-content: center;
    padding: calc(20rem/16) calc(110rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}
.cookie-modal .close {
    position:absolute;
    display:block;
    top: 0;
    right: calc(12rem/16);
    font-size:calc(60rem/16);
    opacity:1;
    color:#3E3D40;
    padding:0;
}
.cookie-modal .modal-footer{
    padding: calc(20rem/16) calc(110rem/16) calc(20rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}
.cookie-modal .modal-body {
    border-top:1px solid rgba(102, 102, 102, 0.31);
    margin: 0 calc(30rem/16);
    padding:2.5rem;
    @media screen and (max-width: 767px) {
        margin: 0 calc(10rem/16);
        padding:2.5rem 1.5rem 1.5rem 1.5rem;
    }
}
.cookie-modal__label {
    font-size:calc(15rem/16);
}
.cookie-modal__switch-toggle {
    height:calc(31rem/16);
    width:calc(51rem/16);
}
.cookie-modal__switch-toggle:before {
    height: 27px;
    width: 27px;
    left: 2px;
    bottom: 2px;
}
input:checked+.cookie-modal__switch-toggle:before {
    transform: translateX(20px);
}
input:checked+.cookie-modal__switch-toggle {
    background-color: var(--color-secondary);
}
.cookie-modal__link {
    display: block;
    margin:1.5rem 0 0 0;
    text-decoration: underline;
}
.cookie-modal .modal-footer .btn {
    width:100%;
    display:block;
    background:var(--color-secondary);
    border-color:var(--color-secondary);
}
.cookie-modal .modal-content {
    border-radius:0;
    padding-top:calc(30rem/16);
}
.cookie-bar {
    background:var(--color-lightgrey);
    color:var(--color-text-default);
    font-size:calc(15rem/16);
}
.cookie-bar__accept  {
    background:var(--color-secondary);
    border-color:var(--color-secondary);
}
.cookie-bar__detail-link {
    font-size:calc(15rem/16);
    letter-spacing:1.5px;
    font-family:var(--font-default-bold);
    text-transform: uppercase;
    text-decoration: none;
    line-height:calc(22/15);
    color:var(--color-secondary);
}