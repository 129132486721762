.hero {
    position:relative;
}
.hero-slider__item {
    position:relative;
    overflow:hidden;
}
.hero__body {
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    display: flex;
    opacity:1;
    justify-content: flex-start;
    padding-bottom: calc(140rem/16);
    align-items: flex-end;
    background: linear-gradient(194.05deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    @media screen and (max-width: 1400px) and (min-width: 768px) {
        padding-bottom: calc(50rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-bottom: calc(45rem/16);
    }
}
.hero__body--light {
    background: linear-gradient(194.05deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
}
.hero__body--transparent {
    background: transparent;
}
.hero__title {
    font-family:var(--font-headline);
    letter-spacing: 0.5px;
    line-height:calc(93/70);
    font-size:calc(70rem/16);
    text-transform: uppercase;
    color:#fff;
    @media screen and (max-width: 767px) {
        font-size:calc(40rem/16);
        line-height: calc(53/40);
    }
}
.hero__subtitle {
    color:#fff;
    line-height: calc(53/40);
    font-size: calc(40rem/16);
    letter-spacing:0.5px;
    font-family:var(--font-headline-light);
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        font-size:calc(20rem/16);
        line-height: calc(27/20);
    }
}