.text-with-medium {
    position:relative;
}

.text-with-medium.text-with-medium--has-background {
    padding: calc(80rem/16) 0;
    background-color:var(--color-lightgrey);
}

.text-with-medium--has-background .svg-pattern {
    @media screen and (max-width: 767px) {
        opacity:0.4;
    }
}