.modal-body {
    padding: calc(10rem/16) calc(110rem/16) calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(15rem/16);
    }
}
.modal--less-spacing .modal-body {
    padding: calc(10rem/16) calc(50rem/16) calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(15rem/16);
    }
}
.modal-header {
    padding: calc(50rem/16) calc(110rem/16) calc(10rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(50rem/16) calc(15rem/16) calc(20rem/16);
    }
}
.modal-text {
    text-align:center;
}
.modal-footer {
    background: linear-gradient(37.28deg, #FBFBFB 0%, #F6F6F6 100%);
    position:relative;
    justify-content: flex-end;
    flex-direction: column;
    padding: calc(30rem/16) calc(110rem/16) calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(50rem/16) calc(15rem/16) calc(20rem/16);
    }
}
.modal-footer:before {
    position:absolute;
    content:'';
    display:block;
    left:0;
    right:0;
    width:100%;
    height:100%;
    background: url("/static/img/pattern/desktop/pattern-dark.svg");
    background-repeat: repeat-x;
    opacity: 0.3;
    pointer-events:none;
    top:0;
}
.modal-title {
    font-size: calc(32rem/16);
    line-height: calc(45/32);
    text-align:center;
    color:var(--color-primary);
    display:block;
    margin: 0 auto;
    font-family:var(--font-headline);
}
.modal-title .icon {
    font-size: calc(27rem/16);
}
.modal-header .modal-close {
    position:absolute;
    top:0;
    right:0;
    padding:calc(15rem/16);
    opacity:1;
    margin:0;
}
.modal-footer__content {
    z-index: 2;
    position: relative;
}