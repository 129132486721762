.collapse-icon:before {
    content: var(--icon-arrow-right);
}
.collapse-icon {
    transform:rotate(270deg);
    transition: all 0.3s ease;
}
.collapsed .collapse-icon {
    transform:rotate(90deg);
}
