@media screen and (max-width: 767px) {
    /*.content-block:not(.content-block--location-teaser) + .content-block--location-teaser {
        padding-top: 0;
    }
    .content-block--location-teaser + .content-block--location-teaser {
        position: relative;
        padding-top: 2rem;
    }
    .content-block--location-teaser + .content-block--location-teaser:before {
        content:'';
        position: absolute;
        left:1.25rem;
        right:1.25rem;
        top:1rem;
        border-top:1px solid rgba(0,0,0,.1);
    }*/
    .location-teaser--multiple .location-teaser--multiple-item + .location-teaser--multiple-item {
        position: relative;
        padding-top: 2.75rem;
    }
    .location-teaser--multiple .location-teaser--multiple-item + .location-teaser--multiple-item:before {
        content:'';
        position: absolute;
        left:10px;
        right:10px;
        top:1.5rem;
        border-top:1px solid rgba(0,0,0,.1);
    }
    .content-block--location-detail + .content-block--location-teaser{
        padding-top:calc(8rem/16);
    }
    .content-block--location-teaser + .content-block--location-teaser,
    .content-block--location-teaser-multiple + .content-block--location-teaser{
        padding-top:calc(6rem/16);
    }
}

.content-block--location-detail + .content-block--location-teaser .location-teaser,
.content-block--location-teaser + .content-block--location-teaser .location-teaser{
    padding-top:calc(40rem/16);
    border-top:1px solid rgba(0,0,0,.1);
    @media screen and (max-width: 767px) {
        padding-top:calc(20rem/16);
    }
}


.location-teaser__title {
    font-size:calc(30rem/16);
    color:var(--color-secondary);
    line-height: calc(45/30);
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom:0;
    }
}
.location-teaser__address {
    font-size: calc(15rem/16);
    line-height: calc(22/15);
    margin-bottom: calc(20rem/16);
}
.location-teaser__address-item a {
    color:var(--color-primary);
}
.location-teaser__address-item a:hover {
    text-decoration: underline;
}
.location-teaser + .location-teaser {
    margin-top: calc(50rem/16);
}