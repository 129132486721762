.content-block {
    padding-top:calc(20rem/16);
    padding-bottom:calc(20rem/16);
}
.content-block:first-of-type {
    padding-top:calc(45rem/16);
}

.content-block--has-background + .content-block.content-block--has-background {
    padding-top: 0;
    margin-top: calc(-20rem/16);
}

@media screen and (min-width: 768px) {
    .content-block {
        padding-top:calc(20rem/16);
        padding-bottom:calc(20rem/16);
    }
    .content-block:first-of-type {
        padding-top:calc(70rem/16);
        padding-bottom:calc(60rem/16);
    }
}

.container-has-sidebar__inner > .content-block:first-child {
    padding-top:0;
}

.content-block.content-block--location-teaser:last-of-type {
    padding-bottom: calc(100rem/16);
}


/* this should remove the space between this (last) element and the page-footer */
.main-content-body > .content-block:last-child.content-block--no-footer-margin:not(.content-block--no-spacing),
.pimcore_area_content:last-child .content-block.content-block--no-footer-margin:not(.content-block--no-spacing) {
    margin-bottom: -20px; /* length of page-wrapper bottom padding */
}

.content-block--smaller-spacing + .content-block--smaller-spacing {
    padding-top:0;
    margin-top:-10px;
}

.content-block--no-spacing.content-block--no-spacing {
    padding:0;
    margin:0;
}