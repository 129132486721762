.form-errors {
    color: var(--color-danger);
    font-size: calc(12rem/16);
}
.form-errors:empty {
    display: none;
}
.form-errors ul, .parsley-errors-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: calc(12rem/16);
    color:var(--color-danger);
    position:relative;

}
.form-group.has-success:not(.no-success-style) .form-control {
   border-bottom: 2px solid var(--color-success);
}
.form-group.has-error .form-control {
    border-bottom: 2px solid var(--color-danger);
}
.has-error .custom-checkbox__box {
    border-width:2px;
}

.change-password__error {
    margin-top: 30px;
    margin-bottom: -15px;
}

