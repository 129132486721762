.custom-select__select {
    border-color:#666;
    appearance:none;
    -webkit-appearance: none;
    border-radius:0;
}
.custom-select__select::-ms-expand {
    display: none;
}
.custom-select__wrapper {
    position:relative;
}
.custom-select__icon {
    position:absolute;
    right:calc(10rem/16);
    font-size:calc(10rem/16);
    pointer-events:none;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
}
.custom-select-unstyled .control-label, .custom-select-unstyled .custom-select__wrapper {
    display: inline-block;
}
.custom-select-unstyled .custom-select__select {
    border:none;
    height:auto;
    padding:0;
    width: calc(45rem/16);
    color: var(--color-secondary);
}
.custom-select-unstyled .custom-select__icon {
    color: var(--color-secondary);
}