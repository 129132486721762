.lg-backdrop {
    background-color: rgba(23, 38, 54, 0.84);
}

.lg-toolbar, .lg-actions .lg-prev, .lg-actions .lg-next {
    background-color:transparent;
    color:var(--color-primary);
}

.lg-sub-html {
    background-color:transparent;
    font-family:var(--font-headline);
    color:#fff;
    font-size:calc(20rem/16);
    padding-bottom:calc(20rem/16);
    @media screen and (max-width: 767px) {
        padding:calc(10rem/16);
    }
}
.lg-actions .lg-prev, .lg-actions .lg-next {
    font-size:calc(40rem/16);
    padding:calc(15rem/16) calc(20rem/16);
    @media screen and (max-width: 767px) {

        font-size: calc(20rem/16);
    }
}

.lg-toolbar .lg-close, .lg-actions .lg-next, .lg-actions .lg-prev {
    color:#fff;
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
}
.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color:var(--color-primary-light);
}
.lg-toolbar .lg-close:hover {
    color:var(--color-primary);
}

.lg-toolbar .lg-close {
    right: 20px;
    top: 20px;
    background-color: #fff;
    width: 4.375rem;
    height: 4.375rem;
    z-index: 9;
    box-shadow: none;
    border: none;
    color: #000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.25rem;
    cursor: pointer;
    transition: all .2s ease;
    margin-top:calc(20rem/16);
    margin-right: calc(10rem/16);
}
.lg-toolbar .lg-close:after {
    content: var(--icon-close);
}

.lg-actions .lg-next:before {
    content: var(--icon-arrow-right);
}

.lg-actions .lg-prev {
    transform:scale(-1);
}
.lg-actions .lg-prev:after {
    content:var(--icon-arrow-right);
}

.lightbox--presse .lg-item {
    max-height: 70vh;
    @media screen and (max-width: 767px) {
        max-height: calc(200rem/16);
    }
}

.lightbox--presse .lg-image {
    background-color: #fff;
    max-height: 50vh;
    margin-top: 10vh;
    @media screen and (max-width: 767px) {
        margin-top: 30px;
        max-height: calc(170rem/16);
    }
}