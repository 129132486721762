.btn {
    text-align:center;
    letter-spacing: 1.5px;
    font-family:var(--font-default-bold);
    text-transform: uppercase;
    font-size:calc(15rem/16);
}
.btn:focus {
    color:#fff;
}
.btn-lg {
    line-height: 22px;
    @media screen and (max-width: 767px) {
        padding:calc(14rem/16) calc(15rem/16);
    }
}
.btn.btn-md {
    padding: calc(12rem / 16) calc(22rem / 16);
}
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.btn--has-icon {
    display:inline-flex;
    align-items:center;
}
.btn--has-icon .icon{
    font-size:calc(21rem/16);
    line-height: calc(10rem/16);
    margin-right: calc(10rem/16);
}
.btn-icon{
    border: none;
}
.btn-icon:focus{
    color: var(--color-primary);
    box-shadow: none;
}