.feature-slide {
    background-color:var(--color-lightgrey);
    position:relative;
    padding:calc(60rem/16) 0 0;
    @media screen and (max-width: 767px) {
        padding:calc(20rem/16) 0 0;
    }
}
.feature-slide__item {
    text-align:center;
    padding: 0 calc(30rem/16) calc(90rem/16);
    @media screen and (max-width: 767px) {
        padding: 0 calc(0rem/16) calc(20rem/16);
        text-align:left;
    }

}
.feature-slide__title {
    font-size:calc(22rem/16);
    line-height:calc(32/22);
    font-family:var(--font-headline);
    @media screen and (max-width: 767px) {
        display:inline-block;
        font-size:calc(16rem/16);
    }
}
.feature-slide__icon-wrapper {
    @media screen and (max-width: 767px) {
        display:inline-block;
        margin-right:calc(5rem/16);
    }
}
.feature-slide__icon {
    font-size:calc(59rem/16);
    color:var(--color-primary);
    padding-bottom:calc(25rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(20rem/16);
        padding-bottom:0;
    }
}
.feature-slide__content {
    padding-top:calc(10rem/16);
    font-size:calc(15rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(14rem/16);
        padding-top:calc(5rem/16);
    }
}
.feature-slide__list {
    z-index: 2;
    position:relative;
}
/*
.feature-slide .svg-pattern {
    height: calc(100rem/16);
}*/
