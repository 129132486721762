.file-upload__wrapper {
    overflow:hidden;
}
.file-upload__item {
    border-top: 1px solid var(--color-grey);
    border-bottom:1px solid var(--color-grey);
}
.file-upload__item + .file-upload__item {
    border-top:none;
}
.file-upload__btn {
    cursor:pointer;
}
.file-upload__btn.disabled {
    pointer-events:none;
}
.file-upload__wrapper .parsley-errors-list {
    position:relative;
    text-align:left;
    font-size: calc(12rem/16);
    line-height: 1.4;
    margin-top: 10px;
}