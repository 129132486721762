.animated-teaser {
    position:relative;
}
.animated-teaser__img {
    transform:scale(1);
    transition: transform 0.4s ease;
}
.animated-teaser:hover .animated-teaser__img{
    transform:scale(1.02);
    transition: transform 0.6s ease;
}
