.img-filter-greyscale {
    transition: filter 0.3s ease;
    filter: grayscale(100%) brightness(140%);

}
.img-filter-greyscale:hover {
    filter: grayscale(0%);
}
.img-filter-opacity {
    opacity:0.6;
    transition: opacity 0.3s ease;

}
.img-filter-opacity:hover {
    opacity:1;
}