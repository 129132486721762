/*Margin Helper*/


/*Padding Helper*/


/*Text Helper*/

/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-lightgrey { color: var(--color-lightgrey); }
.text-middlegrey { color: var(--color-middlegrey); }
.text-grey { color: var(--color-grey); }

/*Background Color Helper*/
.bg-primary { background-color: var(--color-primary); }
.bg-secondary { background-color:var(--color-secondary); }
.bg-lightgrey { background-color:var(--color-lightgrey); }
.bg-grey { background-color:var(--color-grey); }

.font-default {font-family:var(--font-default);}
.font-default-bold {font-family: var(--font-default-bold);}
.font-headline { font-family:var(--font-headline);}

.isCursor { cursor:pointer;}

.fz12 {font-size: calc(12rem/16);}
.fz11 {font-size: calc(11rem/16);}
.fz10 {font-size: calc(10rem/16);}
.fz14 {font-size: calc(14rem/16);}
.fz15 {font-size: calc(15rem/16);}
.fz18 {font-size: calc(18rem/16);}
.fz20 {font-size: calc(20rem/16);}
.fz20-md {
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}

.border--middle-grey { border-color: var(--color-middlegrey) !important; }

.text-transform-none{
    text-transform: none;
}

.no-border {
    border: 0 !important;
}

.lh-1 {
    line-height: 1;
}

.object-fit-contain {
    object-fit: contain;
}

.cart-success-icon.cart-success-icon {
    background: var(--color-success);
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    line-height: 2.5rem;
    position: absolute;
    right: -2rem;
    margin-top: -2.25rem;
    text-align:center;
}
.slider .cart-success-icon.cart-success-icon {
    right: -.25rem;
}

.table td {
    border-top: 1px solid #dee2e6 !important;
}

.table th {
    border-top: 1px solid #212529 !important;
}

.table tr:first-child td {
    border-top: 1px solid #212529 !important;
}