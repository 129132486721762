.navbar-wrapper {
    height:calc(101rem/16);
    background-color: var(--color-lightgrey);
    background-image: url("/static/img/pattern/desktop/navigation.svg");
    background-repeat:repeat-x;
    @media screen and (max-width: 767px) {
        height:calc(51rem/16);
    }
    @media screen and (min-width:768px) and (max-width: 1400px) {
        height:calc(68rem/16);
    }
}
.navbar {
    padding:0;
    z-index: 9;
    @media screen and (max-width: 767px) {
        padding:0;
    }
}
.navbar-main {
    display: flex;
    align-items: center;
    height: 100%;
}
.nav-item__link.active{
    color:var(--color-primary);
}
.nav-item:hover > .nav-item__link {
    color:var(--color-primary);
}
.nav-item__link {
    padding:1.5rem 0;
    transition: all 0.2s ease;
}
.nav-item__sub-menu-wrapper {
    position:absolute;
    display:none;
    text-transform:none;
    font-size:calc(15rem/16);
    /*padding-top:calc(39rem/16);*/
    @media screen and (max-width: 1400px) {
        padding-top: calc(23rem/16);
    }
    @media screen and (min-width: 768px) {
        padding-top: calc(10rem/16);
        padding-bottom: calc(10rem/16);
        margin-top: 1rem;
        min-width:calc(220rem/16);
        box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
        background-color:#fff;
    }
}

@media screen and (min-width: 768px) {
    .nav-item__sub-menu-wrapper--two-cols {
        width:calc(480rem/16);
    }
    .nav-item__sub-menu-wrapper--two-cols .nav-item__sub-menu {
        column-count: 2;
        column-gap: calc(10rem / 16);
    }
}
.nav-item__sub-menu {
    background-color:#fff;
}
.nav-item__sub-menu__item {
    background-color:#fff;
    break-inside: avoid-column;
}
.nav-item__sub-menu__item>a {
    display: block;
    padding:calc(10rem/16) calc(20rem/16);
    transition: background-color 0.3s ease, color 0.2s ease;
}
.nav-item__sub-menu__item > a:hover, .nav-item__sub-menu__item.is-open > a {
    background-color:var(--color-primary);
    color:#fff;
}
.nav-item--has-submenu:hover .nav-item__sub-menu-wrapper, .nav-item--has-submenu.is-open .nav-item__sub-menu-wrapper {
    display:block;
}
.navbar-left {
    padding-left:calc(120rem/16);
    @media screen and (min-width: 768px) and (max-width: 1100px) {
        padding-left: 0;
    }
    @media screen and (min-width: 1101px) and (max-width:1600px) {
        padding-left:calc(20rem/16);
    }
}
.navbar-left .nav-item {
    font-size:calc(18rem/16);
    text-transform:uppercase;
    font-family:var(--font-headline-light);
    line-height:calc(24/18);
    @media screen and (min-width:768px) and (max-width: 970px) {
        font-size:calc(14rem/16);
    }
    @media screen and (min-width: 971px) and (max-width: 1100px) {
        font-size:calc(15rem/16);
    }
    @media screen and (min-width: 1101px) and (max-width: 1400px) {
        font-size:calc(16rem/16);
    }
}
.navbar-left .nav-item + .nav-item {
    padding-left:calc(32rem/16);
    @media screen and (min-width: 768px) and (max-width: 970px) {
        padding-left:calc(10rem/16);
    }
    @media screen and (min-width: 971px) and (max-width: 1199px) {
        padding-left:calc(20rem/16);
    }
    @media screen and (min-width: 1200px) and (max-width: 1450px) {
        padding-left:calc(24rem/16);
    }
}
.navbar-right {
    padding-right:calc(100rem/16);
    @media screen and (max-width:1600px) and (min-width: 768px) {
        padding-right:calc(20rem/16);
    }
    @media screen and (max-width: 767px) {
        display: flex;
        right: 0;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding-right:0;
    }
}
.navbar-right .nav-item {
    font-size:calc(15rem/16);
    font-family:var(--font-headline-light);
    display:flex;
    justify-content:center;
    align-items:center;
    @media screen and (max-width: 767px) {
        display:inline-flex;
        height:30px;
        padding-left:calc(16rem/16);
        padding-right:calc(16rem/16);
    }
}
.navbar-right .nav-item__link {
    display:flex;
    justify-content:center;
    align-items:center;
    @media screen and (max-width: 767px) {
        display:inline-flex;
    }
}
.navbar-right .nav-item + .nav-item {
    padding-left: calc(32rem/16);
    @media screen and (min-width:768px) and (max-width: 991px) {
        padding-left: calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-left:calc(16rem/16);
        border-left:1px solid rgba(102,102,102,0.3);
    }
}
.navbar-right .nav-item .icon {
    margin-right:calc(10rem/16);
    font-size:calc(22rem/16);
    color:var(--color-primary);
    @media screen and (max-width: 767px) {
        margin-right:0;
    }
}
.navbar-right .nav-item .nav-item__flag {
    margin-right: calc(10rem/16);
    width:calc(33rem/16);
    @media screen and (max-width: 767px) {
        margin-right:0;
        width:calc(26rem/16);
    }
}
.navbar-brand {
    @media screen and (min-width:1401px) {
        height:calc(101rem/16);
        padding:0;
    }
    @media screen and (min-width:768px) and (max-width: 980px) {
        margin-left: -25px;

    }
    @media screen and (min-width: 768px) and (max-width: 1400px) {
        width:calc(240rem/16);
    }
    @media screen and (max-width:767px) {
        padding:0;
        margin-right:0;
        height:calc(52rem/16);
        max-width: calc(150rem/16);
    }
}
.navbar-brand img {
    height:100%;
}
.navbar-wrapper {
    position:absolute;
    width: 100%;
    z-index: 999;
}
.navbar-wrapper.not-is-affix {
    opacity:0;
    transform:translateY(-100%);
    transition:opacity 0.2s ease, transform 0.2s ease;
}
.navbar-wrapper.is-affix {
    position:fixed;
    opacity:1;
    transform:translateY(0%);
    transition:opacity 0.2s ease, transform 0.2s ease;
    width:100%;
    z-index: 999;
}