.svg-pattern {
    position: absolute;
    top:0;
    width:100%;
    z-index: 1;
    pointer-events: none;
    height:auto;
}
.pattern-animation {
    opacity:0;
}

.is-in-view .pattern-animation{
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    animation: dash 2s ease alternate forwards;
}

@keyframes dash {
    from {
        opacity:0;
        stroke-dashoffset: 0;
        transform:translateY(-150px) translateX(-50px);
    }
    to {
        opacity:1;
        transform:translateY(0px) translateX(0px);
        stroke-dashoffset: 1000;
    }
}
