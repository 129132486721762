.hero-slider__dots {
    position: absolute;
    bottom:calc(40rem/16);
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
    @media screen and (max-width: 767px) {
        bottom:calc(30rem/16);
    }
}
.hero-slider__dots button:before {
    display:block;
    border-radius:50%;
    box-shadow:none;
    content:'';
    background: #666;
    height:calc(15rem/16);
    width:calc(15rem/16);
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    @media screen and (max-width: 767px) {
        height:calc(10rem/16);
        width:calc(10rem/16);
    }
}
.hero-slider__dots li:not(.slick-active) button:after {
    border-radius:50%;
    box-shadow:none;
    content:'';
    background: #F6F6F6;
    width:calc(15rem/16);
    height:calc(15rem/16);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 2;
    transition: all 0.2s ease;
    @media screen and (max-width: 767px) {
        height:calc(10rem/16);
        width:calc(10rem/16);
    }
}
.hero-slider__dots li:not(.slick-active) button:before {
    width:calc(13rem/16);
    height:calc(13rem/16);
    top: 1px;
    left: 1px;
    @media screen and (max-width: 767px) {
        height:calc(10rem/16);
        width:calc(10rem/16);
        top: 0;
        left: 0;
    }
}
.hero-slider__dots button:hover:after {
    transform:scale(0.01);
}
.hero-slider__dots li button:hover:before {
    width:calc(15rem/16);
    height:calc(15rem/16);
    top: 0;
    left: 0;
    @media screen and (max-width: 767px) {
        height:calc(10rem/16);
        width:calc(10rem/16);
    }
}
.hero-slider__dots li {
    display:inline-block;
    padding-left:calc(15rem/16);
    padding-right:calc(15rem/16);
    @media screen and (max-width: 767px) {
        padding-left:calc(10rem/16);
        padding-right:calc(10rem/16);
    }
}
/*.hero-slider .hero__title {
    opacity:0;
    transform:translateY(-25px);
    transition: transform 0.9s cubic-bezier(0.7, 0, 0.5, 1), opacity 1.5s ease;
}
.hero-slider .slick-current .hero__title {
    opacity:1;
    transform:translateY(0);
}
.hero-slider .hero__sub-title {
    opacity:0;
    transition: opacity 1.9s ease;
}
.hero-slider .slick-current .hero__sub-title {
    opacity:1;
}
.hero-slider .hero__bg-img {
    transform: scale(1.2);
    opacity:0;
    transition: transform 1.2s cubic-bezier(0.5, 0, 0.2, 1), opacity 0.8s ease;
}
.hero-slider .slick-current .hero__bg-img {
    transform: scale(1);
    opacity:1;
}*/
