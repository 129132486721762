body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
}

h1, .h1 {
    font-size:calc(40rem/16);
    font-family:var(--font-headline);
    line-height:calc(55/40);
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
        line-height:calc(40/30);
    }
}
h2, .h2 {
    font-size: calc(30rem/16);
    line-height:calc(45/30);
    font-family:var(--font-headline);
    @media screen and (max-width: 767px) {
        font-size: calc(25rem/16);
    }
}
h3, .h3 {
    font-size: calc(22rem/16);
    line-height:calc(32/22);
    font-family:var(--font-headline);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
        line-height:calc(40/30);
    }
}
h4, .h4 {
    font-size: calc(20rem/16);
}
h5, .h5 {
    font-size: calc(18rem/16);
}
h6, .h6 {
    font-size: 1em;
}