html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(1320rem/16);
    width: 100%;
    @media screen and (max-width: 767px) {
        padding-left:calc(20rem/16);
        padding-right:calc(20rem/16);
    }
}

.page-wrapper {
    padding-top: calc(101rem/16);
    overflow-x:hidden;
    @media screen and (max-width: 767px) {
        padding-top: calc(51rem/16);
    }
    @media screen and (min-width:768px) and (max-width: 1400px) {
        padding-top:calc(68rem/16);
    }
}

.main-content-body--full-height, .main--full-height {
    min-height: calc(100vh - 145px);
    @media screen and (max-width: 767px) {
        min-height: calc(100vh - 95px);
    }
}