.page-item {
    font-size:calc(22rem/16);
    line-height:calc(32/22);
    font-family:var(--font-headline);
    position:relative;
}
.page-link__next, .page-link__prev {
    font-size:calc(14rem/16);
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: calc(50rem/16) 0;
}
.page-item:before {
    content:'';
    height:calc(2rem/16);
    width: calc(22rem/16);
    margin: 0 auto;
    left:0;
    right:0;
    position:absolute;
    bottom:0;
    background-color:var(--color-primary);
    opacity:0;
    transform: translateY(-4px);
    transition: opacity 0.2s ease, transform 0.2s ease;
}
.page-item.active:before, .page-item:hover:before {
    opacity:1;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.2s ease;
}
.page-item:hover:before {
    background-color:var(--color-text-default);
}
.page-item.disabled {
    opacity:0;
    display:none;
}
.page-item--arrow:before, .page-item--more:before {
    display: none;
}
.page-item--arrow:hover .page-link, .page-item--more:hover .page-link{
    color: var(--color-primary);
}