.catalogue-teaser {
    background: linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 100%);
    border: 1px solid var(--color-grey);
}
.catalogue-teaser__body {
    position:relative;
    padding: calc(50rem/16) calc(25rem/16) calc(30rem/16);
    margin-top: calc(-50rem/16);
    background-color:var(--color-grey);
    display: flex;
    flex-direction: column;
    flex-grow:1;
}
.catalogue-teaser__body:before {
    content:'';
    position: absolute;
    left:0;
    right:0;
    width:100%;
    top:0;
    height:calc(80rem/16);
    background-image: url("/static/img/pattern/mobile/pattern-dark.svg");
    opacity:0.2;
    background-size: contain;
}
.catalogue-teaser__img {
    z-index: 2;
}
.catalogue-teaser__img-wrapper {
    pointer-events: none;
}
.catalogue-teaser__date {
    font-size:calc(15rem/16);
    line-height: calc(22/15);
}
.catalogue-teaser__title {
    font-size:calc(22rem/16);
    line-height:calc(32/22);
    font-family:var(--font-headline);
    margin-bottom:calc(24rem/16);
    transition: color 0.2s ease;
}
.catalogue-teaser:hover .catalogue-teaser__title {
    color: var(--color-secondary);
}