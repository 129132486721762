.menu-icon__wrapper {
    position:relative;
    width:calc(22rem/16);
    height:calc(18rem/16);
}
.menu-icon {
    position: relative;
    width: calc(22rem/16);
    height: calc(18rem/16);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    display:block;
}
.menu-icon__line {
    position: absolute;
    right: 0;
    background-color: rgba(62,61,64,1);
    height: calc(2rem/16);
    width: calc(21rem/16);
    border-radius:2px;
    pointer-events: none;
    transition: all 0.2s ease;
}
.menu-icon__line-1 {
    top: 0;
}
.menu-icon__line-2 {
    top: calc(7rem/16);
}
.menu-icon__line-3 {
    top: calc(14rem/16);
    bottom: 0;
}
.active .menu-icon__line-1 {
    opacity:0;
}
.active .menu-icon__line-2 {
    transform: rotate(45deg) translateX(calc(7rem/16));
    width: calc(27rem/16);
    right:calc(4rem/16);
    top: calc(3rem/16);
}
.active .menu-icon__line-3 {
    transform: rotate( -45deg) translateX(calc(7rem/16)) translateY(0);
    width: calc(27rem/16);
    right:calc(4rem/16);
    top: calc(13rem/16);
}