.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    width: calc(70rem/16);
    text-align: center;
}
.loading-spinner__item {
    width: calc(10rem/16);
    height: calc(28rem/16);
    transform: skewX(-20deg);
    background-color: var(--color-primary);
    display: inline-block;
    animation: loading-spinner-rotate 1.4s infinite ease-in-out both;
}
.loading-spinner__item--1 {
    animation-delay: -0.32s;
}
.loading-spinner__item--2 {
    animation-delay: -0.16s;
}
@keyframes loading-spinner-rotate {
    0%, 80%, 100% {
        transform: scale(0) skewX(-20deg);
    }
    40% {
        transform: scale(1.0) skewX(-20deg);
    }
}
.loading-overlay-container--product-grid .loading-spinner {
    margin-top: calc(300rem/16);
}
.loading-spinner--xs {
    width: calc(28rem/16);
    margin-left: auto;
    margin-right: 0;
    top: 50%;
    height: 100%;
    align-items: center;
    display:flex;
}
.loading-spinner--xs .loading-spinner__item{
    width: calc(4rem/16);
    margin-right: calc(2rem/16);
    height: calc(19rem/16);
}