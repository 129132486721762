.image-slider .slick-dots {
    display:none !important;
}
.image-slider__item {
    display:block;
}
.image-slider__img {
    padding:calc(5rem/16);
}
.image-slider__wrapper {
    position:relative;
    @media screen and (max-width: 1400px) and (min-width: 768px) {
        padding-left:calc(40rem/16);
        padding-right: calc(40rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-left:calc(20rem/16);
        padding-right:calc(20rem/16);
    }
}
.image-slider__arrow.arrow-prev {
    display: block;
    position: absolute;
    top: 50%;
    left: -50px;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
        left:calc(-20rem/16);
    }
    @media screen and (max-width: 1400px) and (min-width: 768px) {
        left:calc(-40rem/16);
    }
}
.image-slider__arrow.arrow-next {
    display: block;
    position: absolute;
    top: 50%;
    right: -50px;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
        right:calc(-20rem/16);
    }
    @media screen and (max-width: 1400px) and (min-width: 768px) {
        right:calc(-40rem/16);
    }
}