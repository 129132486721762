.page-intro__title {
    font-size:calc(40rem/16);
    font-family:var(--font-headline);
    line-height:calc(55/40);
    color:var(--color-primary);
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
        line-height:calc(40/30);
    }
}
.page-intro__subtitle {
    font-size:calc(30rem/16);
    line-height: calc(45/30);
    font-family:var(--font-headline);
    margin-bottom: calc(30rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(25rem/16);
        line-height:calc(35/25);
        margin-bottom: calc(20rem/16);
    }
}