.toggle-pw{
    margin: 0 auto;
    vertical-align: middle;
    font-size: calc(18rem/16);
}
.toggle-pw__btn{
    position: absolute;
    margin: auto 0;
    right: 0;
    top: 0;
}
.toggle-pw__btn--bottom {
    top: auto;
    bottom: 0;
}
.toggle-pw--hide{
    display: none;
}
.toggle-pw--show{
    display: inline-block;
}
.toggle-pw__btn.active .toggle-pw--hide{
    display: inline-block;
}
.toggle-pw__btn.active .toggle-pw--show{
    display: none;
}
/*.toggle-pw__form .input-group {
     border: 1px solid #666;
 }

.toggle-pw__form .input-group .form-control{
    border: none
}
.toggle-pw__form .input-group .parsley-errors-list{
    position:absolute;
    top: 50px
 }*/
