.timeline-wrapper {
    position:relative;
}
.timeline-wrapper:before {
    content:'';
    display:block;
    position:absolute;
    z-index: -1;
    background-color:var(--color-grey);
    width:calc(1rem/16);
    left:0;
    right:0;
    height:100%;
    margin:0 auto;
}
.timeline-row + .timeline-row {
    margin-top:calc(-20rem/16);

    @media screen and (max-width:767px) {
        margin-top:calc(42rem/16);
    }
}
.timeline-teaser {
    position:relative;
    @media screen and (max-width: 767px) {
        padding-top: calc(35rem/16);
    }
}
.timeline-teaser__img-wrapper {
    position:relative;
}
.timeline-teaser__badge {
    width: calc(147rem/16);
    height: calc(121rem/16);
    background-repeat: no-repeat;
    background-size: cover;
    font-size:calc(30rem/16);
    color:#fff;
    display:flex;
    justify-content:center;
    align-items:center;
    line-height:calc(50/30);
    font-family:var(--font-headline);
    position: absolute;
    bottom: calc(-28rem/16);
    z-index: 1;
    @media screen and (max-width: 767px) {
        background-image: url("/static/img/backgrounds/timeline-badge-bg.svg");
        top:0;
        bottom:initial;
        width: calc(130rem/16);
        min-height: calc(70rem/16);
        height:auto;
        line-height:1.3;
        margin:0 auto;
        left:0;
        right:0;
        padding: .625rem 1.75rem .625rem calc(20rem/16);

    }
}
.timeline-teaser__body {
    position:relative;
    background-color:var(--color-grey);
    @media screen and (max-width: 767px) {
        padding: calc(30rem/16) calc(30rem/16) calc(40rem/16);
    }
    @media screen and (min-width: 767px) {
        padding: calc(40rem/16) calc(80rem/16) calc(55rem/16);
        box-shadow: inset 0 0 0 calc(2rem/16) transparent;
        transition: background-color 0.3s ease-in-out, box-shadow 0.5s linear;
    }
}
.timeline-teaser--without-img .timeline-teaser__body {
    @media screen and (max-width: 767px) {
        padding: calc(70rem/16) calc(30rem/16) calc(40rem/16);
    }
}
.timeline-teaser__title {
    font-size:calc(22rem/16);
    font-family:var(--font-headline);
    line-height:calc(32/22);
    margin-bottom: calc(22rem/16);
}
@media screen and (min-width: 768px) {
    .timeline-teaser--without-img .timeline-teaser__badge {
        bottom: 50%;
        transform:translateY(50%);
    }
    .timeline-teaser--left {
        padding-right: calc(40rem/16);
        padding-left: calc(90rem/16);
    }
    .timeline-teaser--right {
        padding-left: calc(40rem/16);
        padding-right: calc(90rem/16);
    }
    .timeline-teaser--left .timeline-teaser__badge {
        background-image: url("/static/img/backgrounds/timeline-badge-bg.svg");
        padding: .625rem 1.75rem .625rem calc(20rem/16);
        left: calc(-90rem/16);
        justify-content: flex-start;
    }
    .timeline-teaser--right .timeline-teaser__badge {
        background-image: url("/static/img/backgrounds/timeline-badge-bg-right.svg");
        padding: calc(10rem/16) calc(20rem/16) calc(10rem/16) calc(28rem/16);
        right: calc(-90rem/16);
        justify-content: flex-end;
    }
    .timeline-teaser:before {
        content:'';
        position:absolute;
        top: calc(30rem/16);
        display:block;
        width: 0;
        height: 0;
    }
    .timeline-teaser--left:before {
        border-top: 16px solid transparent;
        border-left: 20px solid var(--color-grey);
        border-bottom: 16px solid transparent;
        right:calc(20rem/16);
    }
    .timeline-teaser--right:before {
        border-top: 16px solid transparent;
        border-right: 20px solid var(--color-grey);
        border-bottom: 16px solid transparent;
        left:calc(20rem/16);
    }
    .timeline-teaser:after {
        content:'';
        position:absolute;
        right:0;
        top: calc(30rem/16);
        display:block;
        width: calc(16rem/16);
        border-radius: 50%;
        height: calc(16rem/16);
        background-color:var(--color-primary);
    }
    .timeline-teaser:hover:after {
        animation: pulse 2s ease-in-out forwards infinite;
    }
    .timeline-teaser--left:after {
        right:0;
        transform: translateX(50%) translateY(50%);
    }
    .timeline-teaser--right:after {
        left:0;
        transform: translateX(-50%) translateY(50%);
    }
    .timeline-teaser:hover .timeline-teaser__body {
        background-color:var(--color-lightgrey);
        box-shadow: inset 0 0 0 calc(2rem/16) var(--color-grey);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(232, 131, 0, 0.41);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(232, 131, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(232, 131, 0, 0);
    }
}