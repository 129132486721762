.text-slide {
    padding: calc(40rem/16) 0 calc(50rem/16);
    position:relative;
    @media screen and (max-width: 767px) {
        padding: calc(30rem/16) 0;
    }
}
.text-slide--has-background {
    padding: calc(80rem/16) 0;
    background-color:var(--color-lightgrey);
}
.text-slide__title-block .title-block__title {
    color:var(--color-primary);
}
.text-slide__title-block .title-block__wysiwyg {
    padding-top: calc(20rem/16);
}
.text-slide__title-block {
    z-index: 2;
}
.text-slide--has-background .svg-pattern {
    @media screen and (max-width: 767px) {
        opacity:0.4;
    }
}