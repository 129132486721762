.checkbox-btn {
    display: inline-block;
    margin:0 1rem 1rem 0;
}

.checkbox-btn input {
    position: absolute;
    display: none;
    color: #fff !important;
}

.checkbox-reset-btn,
.checkbox-btn input + span {
    -webkit-appearance: none;
    background: transparent;
    display: inline-block;
    cursor: pointer;
    padding:.25rem 1rem;
    color:var(--color-text-default);
    border:1px solid var(--color-text-default);
}

.checkbox-reset-btn {
    margin-right:1rem;
}

.checkbox-reset-btn.active,
.checkbox-btn input:checked + span {
    color:var(--color-primary);
    border-color:var(--color-primary);
}