.slick-dots {
    list-style: none;
}
.slick-dots button {
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    list-style: none;
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
}
.slick-arrow.disabled {
    opacity:0.4;
}