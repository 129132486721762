.feature-info {
    text-align: left;
    padding:calc(40rem/16) 0 0 0;
    border-top:1px solid rgba(0,0,0,.1);

    @media screen and (max-width: 767px) {
        padding:calc(20rem/16) 0 0 0;
    }
}
.feature-info__icon {
    width: calc(66rem/16);
    height: calc(66rem/16);
    border-radius: 50%;
    border:3px solid var(--color-primary);
    font-size:calc(40rem/16);
    color:var(--color-primary);
    display:flex;
    justify-content:center;
    align-items:center;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        margin-bottom: calc(10rem/16);
        width: calc(40rem/16);
        height: calc(40rem/16);
        font-size:calc(28rem/16);
        border:1px solid var(--color-primary);
    }
}
.feature-info__icon .icon-lkw {
    font-size: calc(31rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(22rem/16);
    }
}
.feature-info__title {
    font-size:calc(22rem/16);
    line-height: calc(32/22);
    font-family:var(--font-headline);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(10rem/16);
        font-size:calc(18rem/16);
    }
}
.feature-info__item {
    @media screen and (max-width: 767px) {
        margin-top:calc(10rem/16);
        margin-bottom: calc(20rem/16);
    }
}

@media screen and (max-width: 767px) {
    .feature-info__col + .feature-info__col .feature-info__item {
        margin-bottom: 0;
    }
}