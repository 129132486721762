.main-nav-overlay .main-nav-overlay__item-title.collapsed {
    color:var(--color-text-default);
}
.main-nav-overlay .main-nav-overlay__item-title {
    font-size:calc(18rem/16);
    line-height:calc(24/18);
    font-family:var(--font-headline);
    text-transform:uppercase;
    color:var(--color-primary);
}
.main-nav-overlay__item {
    margin-bottom:calc(40rem/16);
}
.main-nav-overlay__list {
    padding-left:calc(30rem/16);
}
.main-nav-overlay__list-item {
    font-size:calc(15rem/16);
    line-height:calc(20/15);
    font-family:var(--font-headline-light);
}
.main-nav-overlay__list-item.active {
    color:var(--color-primary);
}
.main-nav-overlay__list-item {
    padding-top:calc(20rem/16);
}
.main-nav-overlay__list-item-title {
    @media screen and (max-width: 767px) {
        font-family:var(--font-headline);
    }
}