.simple-teaser__title {
    font-size: calc(22rem/16);
    line-height: calc(32/22);
    color: var(--color-text-default);
    font-family:var(--font-headline);
    margin-bottom: calc(5rem/16);
    transition: color 0.2s ease;
}
.simple-teaser__body {
    position:relative;
    padding: calc(25rem/16) calc(25rem/16) calc(50rem/16);
    background-color:var(--color-lightgrey);
}
.simple-teaser__body:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    height: 5rem;
    background-image: url("/static/img/pattern/mobile/pattern-dark.svg");
    opacity: .2;
    background-size: contain;
}
.simple-teaser {
    position: relative;
    transition: box-shadow 0.2s ease;
    background-color:var(--color-lightgrey);
    @media screen and (min-width: 768px) {
        height:100%;
    }
}
.simple-teaser__img-wrapper {
    position:relative;
}
.simple-teaser__img-wrapper:before {
    content:'';
    background-color: rgba(0,0,0,0.1);
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity:1;
    transition: opacity 0.2s ease;
}
.simple-teaser:hover {
    box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
}
.simple-teaser:hover .simple-teaser__title {
    color: var(--color-primary);
}
.simple-teaser__img {
    transition: transform 0.9s ease;
}
.simple-teaser:hover .simple-teaser__img {
    transform: scale(1.03);
}
.simple-teaser:hover .simple-teaser__img-wrapper:before  {
    opacity:0;
}