.embed-responsive-cover {
    height: 100%;
}
.embed-responsive-cover .embed-responsive-item {
    width: auto;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
}
.embed-responsive-hero {
    padding-bottom: calc(70 / 192 * 100%);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(250 / 375 * 100%);
    }
}
.embed-responsive-hero-small {
    padding-bottom: calc(50 / 192 * 100%);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(250 / 375 * 100%);
    }
}
.embed-responsive-66by37 {
    padding-bottom: calc(37 / 66 * 100%);
}
.embed-responsive-cta-img-slide {
    padding-bottom: calc(400 / 1920 * 100%);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(358 / 375 * 100%);
    }
    @media screen and (max-width: 991px) and (orientation: landscape) {
        padding-bottom: 35%;
    }
}
.embed-responsive-img-slider {
    padding-bottom: calc(147 / 206 * 100%);
}
.embed-responsive-catalogue-teaser {
    padding-bottom: calc(290 / 310 * 100%);
}
.embed-responsive-timeline-teaser {
    padding-bottom: calc(347 / 520 * 100%);
}
.embed-responsive-company-location-teaser {
    padding-bottom: calc(350 / 750 * 100%);
}
.embed-responsive-jobs-teaser {
    padding-bottom: calc(217 / 330 * 100%);
}
.embed-responsive-sidebar-img {
    padding-bottom: calc(3 / 2 * 100%);
}
.embed-responsive-product-teaser {
    padding-bottom: calc(296 / 306 * 100%);
}
.embed-responsive-3by2 {
    padding-bottom: calc(2 / 3 * 100%);
}
.embed-responsive-product-item {
    padding-bottom: calc(150 / 130 * 100%);
}
.embed-responsive-thumb-slider {
    padding-bottom: calc(450 / 650 * 100%);
}
.embed-responsive-13by8 {
    padding-bottom: calc(8 / 13 * 100%);
}
.embed-responsive-5by2 {
    padding-bottom: calc(2 / 5 * 100%);
}