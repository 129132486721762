.ui-datepicker {
    z-index: 10000!important;
}

.datepicker__input[readonly] {
    background-color: transparent;
    opacity: 1;
    cursor: pointer;
}
.ui-datepicker .ui-state-disabled {
    background-color: var(--color-grey);
}
.ui-datepicker-prev:after {
    content: var(--icon-arrow-right);
}
.ui-datepicker-prev {
    transform: rotate(180deg);
}

.datepicker{
    position: relative;
}
.datepicker:before {
    content: var(--icon-kalender);
    font-family: iconfont;
    color: var(--color-secondary);
    speak: none;
    position: absolute;
    top: calc(33rem/16);
    right: calc(7rem/16);
    font-size: calc(22rem/16);
    padding: calc(4rem/16);
    pointer-events: none;
}

.datepicker .form-control{
    padding-right: calc(32rem/16);
}

.datepicker .form-control.is-invalid,
.datepicker .form-control.valid {
    background-position: center right 2.25rem;
}