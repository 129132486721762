.jobs-teaser--has-background {
    background-color:var(--color-lightgrey);
}
.jobs-teaser {
    padding: calc(30rem/16) 0;
}
.jobs-teaser__body {
    padding: calc(27rem/16) calc(70rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) calc(0rem/16);
    }
}
.jobs-teaser__title {
    font-size: calc(22rem/16);
    line-height: calc(32/22);
    font-family:var(--font-headline);
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.jobs-teaser__location {
    font-size: calc(15rem/16);
    margin-top: calc(20rem/16);
    display: flex;
    align-items: center;
}
.jobs-teaser__location .icon {
    font-size: calc(18rem/16);
    margin-right: calc(15rem/16);
}