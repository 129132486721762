
.language-switch__flag {
    width: calc(26rem/16);
    height:auto;
    margin-right: calc(10rem/16);
}
.language-switch__item {
    display: flex;
    align-items: center;
    border-top:1px solid var(--color-grey);
    border-bottom: 1px solid var(--color-grey);
    padding: calc(15rem/16) calc(10rem/16);
    transition: all 0.2s ease;
}
.language-switch__item + .language-switch__item {
    border-top: none;
}
.language-switch__item:hover {
    background-color:var(--color-secondary);
    color:#fff;
}