.contact-slide {
    background-color:var(--color-lightgrey);
    padding: calc(50rem/16) 0;
    position:relative;
}
.contact-slide__img-wrapper {
    border-radius:100%;
    max-width: calc(190rem/16);
    overflow:hidden;
    @media screen and (max-width: 767px) {
        max-width: calc(100rem/16);
    }
}
.contact-slide__body {
    z-index: 1;
    position:relative;
}
.contact-slide__title {
    margin-bottom: calc(30rem/16);
}
.contact-slide__address, .contact-slide__address-item {
    font-size: calc(15rem/16);
    line-height: calc(22/15);
}
.contact-slide__address-item a {
    color:var(--color-primary);
}
.contact-slide__address-item a:hover {
    text-decoration: underline;
}
.contact-slide__contact-title {
    margin-bottom: calc(5rem/16);
}